<script setup>
</script>
<template>
  <NuxtLayout name="mobile-base">
    <template #mobile-header>
      <mobile-header :title="$t('client.update_profile')">
        <template #header-left>
          <mobile-back-button></mobile-back-button>
        </template>
      </mobile-header>
    </template>

    <div class="px-4">
      <NuxtLink :to="localePath({ name: 'account.profile.change_name' })" class="my-3 bg-white p-3 rounded-lg border flex items-center justify-between text-gray-600 cursor-pointer">
        <h4> <i class="fa-solid fa-user me-2"></i>{{ $t('client.change_name') }}</h4>
        <p> <i class="fa-solid fa-chevron-right"></i> </p>
      </NuxtLink>
      <NuxtLink :to="localePath({ name: 'account.profile.change_email' })" class="my-3 bg-white p-3 rounded-lg border flex items-center justify-between text-gray-600 cursor-pointer">
        <h4> <i class="fa-solid fa-envelope me-2"></i>{{ $t('client.change_email') }}</h4>
        <p> <i class="fa-solid fa-chevron-right"></i> </p>
      </NuxtLink>
      <NuxtLink :to="localePath({ name: 'account.profile.mobile_phones' })" class="my-3 bg-white p-3 rounded-lg border flex items-center justify-between text-gray-600 cursor-pointer">
        <h4> <i class="fa-solid fa-phone me-2"></i>{{ $t('client.mobile_phones') }}</h4>
        <p> <i class="fa-solid fa-chevron-right"></i> </p>
      </NuxtLink>
      <NuxtLink :to="localePath({ name: 'account.profile.change_password' })" class="my-3 bg-white p-3 rounded-lg border flex items-center justify-between text-gray-600 cursor-pointer">
        <h4> <i class="fa-solid fa-lock me-2"></i>{{ $t('client.change_password') }}</h4>
        <p> <i class="fa-solid fa-chevron-right"></i> </p>
      </NuxtLink>
    </div>

    <template #mobile-footer></template>
  </NuxtLayout>
</template>